import { Image } from '@chakra-ui/react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import image1 from '../images/item1.webp';
import image2 from '../images/item2.webp';
import image3 from '../images/item3.webp';

const CarouselImage = () => {
  return (
    <Carousel indicators={false} pause={false} controls={false} interval={2000}>
      <CarouselItem align={'center'}>
        <Image src={image1} maxH={['40vh', '40vh', '80vh']} />
      </CarouselItem>
      <CarouselItem align={'center'}>
        <Image src={image2} maxH={['40vh', '40vh', '80vh']} />
      </CarouselItem>
      <CarouselItem align={'center'}>
        <Image src={image3} maxH={['40vh', '40vh', '80vh']} />
      </CarouselItem>
    </Carousel>
  );
};

export default CarouselImage;
