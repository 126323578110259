import { Box, Flex, Spacer, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import CarouselImage from '../components/CarouselImage';
import Header from '../components/Header';
import Loading from '../components/Loading';
import PopUpMenu from '../components/PopUpMenu';
import SideBottom from '../components/SideBottom';
import { useAuthContext } from '../context/AuthContext';

const Welcome = () => {
  const { user, login } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleGoogleLoginLoginResponse = useCallback(
    async function (response) {
      setIsLoading(true);

      try {
        const token = response.credential;
        login(token);
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    },
    [login]
  );
  if (isLoading) {
    return <Loading />;
  }

  if (user) {
    if (!user.uid) {
      return <Navigate to="/complete" replace={true} />;
    } else {
      return <Navigate to="/home" replace={true} />;
    }
  }

  return (
    <Box bg={'#662D91'}>
      <Flex direction={['column', 'column', 'row']}>
        <Flex
          bg={'white'}
          w={['100vw', '100vw', '80vw']}
          h={['70vh', '70vh', '100vh']}
          borderRightRadius={[null, null, 'xl']}
          borderBottomRadius={['xl', 'xl', null]}
          p={'5'}
          direction={'column'}
        >
          <Header onClick={onOpen} />
          <Spacer />
          <CarouselImage />
          <Spacer />
        </Flex>
        <Box
          bg={'transparent'}
          w={['100vw', '100vw', '20vw']}
          h={['30vh', '30vh', '100vh']}
        >
          <SideBottom />
        </Box>
      </Flex>
      <PopUpMenu
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
        handleGoogleLogin={handleGoogleLoginLoginResponse}
      />
    </Box>
  );
};

export default Welcome;
