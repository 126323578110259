import { Box, Text, VStack } from '@chakra-ui/react';
import styles from '../styles/Error.module.css';

const Error = () => {
  return (
    <VStack w={'100vw'} h={'100vh'} justify={'center'}>
      <Box className={styles.number}>404</Box>
      <Box className={styles.text}>
        <Text as={'span'}>Ooops...</Text>
        <Text>page not found</Text>
      </Box>
    </VStack>
  );
};

export default Error;
