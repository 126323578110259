import { Box, Center, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { RiWalletFill } from 'react-icons/ri';
import logo from '../images/logo.png';

const Header = ({ onClick, showMenu }) => {
  return (
    <>
      <Box w={'100%'} h={'20%'} display={['none', 'none', 'block']}>
        <Flex w={'100%'} justify={'center'} align={'center'}>
          <Box w={'10%'} display={showMenu}>
            <Link to={''} onClick={onClick}>
              <FiMoreVertical size={'50px'} />
            </Link>
          </Box>
          <Spacer />
          <Center>
            <Image src={logo} />
          </Center>
          <Spacer />
          <Box w={'10%'} />
        </Flex>
      </Box>

      <Box w={'100%'} h={'20%'} display={['block', 'block', 'none']}>
        <Flex
          w={'100%'}
          justify={'space-between'}
          align={'center'}
          display={showMenu}
        >
          <Link to={''} onClick={onClick}>
            <FiMoreVertical size={'30px'} />
          </Link>
          <Link to={''} onClick={() => console.log('topup')}>
            <Flex
              bg={'#662D91'}
              py={'2'}
              px={'5'}
              borderRadius={'100px'}
              align={'center'}
            >
              <RiWalletFill color={'white'} />
              <Text fontWeight={'black'} color={'white'} ml={'3'}>
                TOP UP
              </Text>
            </Flex>
          </Link>
        </Flex>
        <Center>
          <Image src={logo} maxW={'50vw'} />
        </Center>
      </Box>
    </>
  );
};

export default Header;
