import { useCallback, useState } from "react";

const useLocalStorage = () => {
  const [value, setValue] = useState(null);

  const setItem = useCallback((keyName, initialValue) => {
    localStorage.setItem(keyName, JSON.stringify(initialValue));
    setValue(initialValue);
  }, []);
  const getItem = useCallback((keyName) => {
    const value = localStorage.getItem(keyName);
    const parsedValue = JSON.parse(value);
    setValue(parsedValue);
    return parsedValue;
  }, []);

  const removeItem = useCallback((keyName) => {
    localStorage.removeItem(keyName);
    setValue(null);
  }, []);

  const clearSession = useCallback(() => {
    localStorage.clear();
    setValue(null);
  }, []);
  
  return { value, setItem, getItem, removeItem, clearSession };
};

export { useLocalStorage };
