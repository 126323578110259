import { Box, Button, Center, Text } from '@chakra-ui/react';
import { async } from '@firebase/util';
import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthContext';

const Reward = () => {
  const { getUserEvent } = useAuthContext();
  const [id, setId] = useState('');

  const _init = useCallback(async () => {
    try {
      const getId = await getUserEvent();
      const rand = Math.floor(Math.random() * (getId.length + 1));
      setId(getId[rand]);

      console.log(getId);
    } catch (err) {
      console.log(err);
    }
  }, [getUserEvent]);

  useEffect(() => {
    _init();
  }, [_init]);

  console.log(id);

  return (
    <Center
      w={'100vw'}
      h={'100vh'}
      bg={'#662D91'}
      flexDir={'column'}
      justifyContent={'space-evenly'}
    >
      <Box>
        <Text
          fontSize={'10vw'}
          letterSpacing={'1vw'}
          color={'white'}
          fontWeight={'black'}
        >
          {/* {characters} */}
          {id}
        </Text>
      </Box>
      <Box>
        {/* <Button onClick={handleSuffle}>
          <Text>{shuffle ? 'SHUFFLE' : 'STOP'}</Text>
        </Button> */}
      </Box>
    </Center>
  );
};

export default Reward;
