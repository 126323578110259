import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { routers } from './configs/routes';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={routers} />
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
