import { ColorModeScript } from "@chakra-ui/react";
import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <>
    <ColorModeScript />
    <AuthProvider>
      <App />
    </AuthProvider>
  </>
);
serviceWorker.unregister();
