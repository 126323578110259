import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { RiWalletFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import playstore from '../images/playstore.png';
import appstore from '../images/appstore.png';

const SideBottom = () => {
  return (
    <VStack h={'100%'} justify={'space-between'} py={'10px'} px={'5px'}>
      <Box display={['none', 'none', 'block']}>
        <Link to={''} onClick={() => console.log('topup')}>
          <Flex
            bg={'#29D2E2'}
            py={'2'}
            px={'5'}
            borderRadius={'100px'}
            align={'center'}
          >
            <RiWalletFill color={'white'} />
            <Text fontWeight={'black'} color={'white'} ml={'3'}>
              TOP UP
            </Text>
          </Flex>
        </Link>
      </Box>
      <VStack>
        <Text fontWeight="black" color="white" fontSize="20px">
          Download now:
        </Text>
        <Flex>
          <Box px={'5px'}>
            <Link
              to={
                'https://play.google.com/store/apps/details?id=com.bsoft.beelli'
              }
              target={'_blank'}
            >
              <Image src={playstore} />
            </Link>
          </Box>
          <Box px={'5px'}>
            <Link to={''}>
              <Image src={appstore} />
            </Link>
          </Box>
        </Flex>
        <Flex color={'white'} justify={'space-between'} w={'100%'} maxH={'20%'}>
          <Link to={'https://beelli.com/public/privacy.html'} target={'_blank'}>
            <Text textAlign={'center'}>Privacy Policy</Text>
          </Link>
          <Link to={'https://beelli.com/public/terms.html'} target={'_blank'}>
            <Text textAlign={'center'}>Terms & conditions</Text>
          </Link>
        </Flex>
      </VStack>
    </VStack>
  );
};

export default SideBottom;
