import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Loading from '../components/Loading';
import logo from '../images/logo.png';
import logoWhite from '../images/logo-white.png';
import phoneSignupBanner from '../images/phone-signup-banner.webp';
import { Link } from 'react-router-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { auth } from '../configs/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useAuthContext } from '../context/AuthContext';

const Signup = () => {
  const { loginWithPhone } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isClick, setIsClick] = useState(false);
  const [phone, setPhone] = useState('');
  const [pin, setPin] = useState('');
  const [isPhoneNumber, setIsPhoneNumber] = useState(true);
  const handlePhoneChange = event => setPhone(event.target.value);
  const handlePinChange = event => setPin(event);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const generateChapta = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: response => {},
      },
      auth
    );
  };

  const handleSignin = () => {
    setIsClick(true);
    if (phone === '' || phone.length < 10) {
      setTimeout(() => {
        setIsClick(false);
      }, 1000);
      return;
    }

    generateChapta();
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = process.env.REACT_APP_COUNTRY_CODE + phone;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(confirmationResult => {
        setIsPhoneNumber(false);

        window.confirmationResult = confirmationResult;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsClick(false));
  };

  const handleVerify = () => {
    setIsClick(true);
    if (pin === '' || pin.length < 6) {
      setTimeout(() => {
        setIsClick(false);
      }, 1000);
      return;
    }

    const confirmationResult = window.confirmationResult;

    confirmationResult
      .confirm(pin)
      .then(result => {
        loginWithPhone(phone, result.user);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsClick(false));
  };

  return (
    <Box bg={'#662D91'}>
      <Flex direction={['column', 'column', 'row']}>
        <Flex
          bg={'white'}
          w={['100vw', '100vw', '60vw']}
          h={['10vh', '10vh', '100vh']}
          borderRightRadius={[null, null, 'xl']}
          borderBottomRadius={['xl', 'xl', null]}
          pt={['0', '0', '5']}
          pb={['0', '0', '5']}
          pl={['0', '0', '5']}
          pr={['0', '0', '5']}
          direction={'column'}
        >
          <Center w={'100%'} h={'100%'} display={['flex', 'flex', 'none']}>
            <Image src={logo} maxH={['30px', '50px']} />
          </Center>

          <Center display={['none', 'none', 'flex']}>
            <Image src={logo} />
          </Center>
          <Center display={['none', 'none', 'flex']} h={'100%'}>
            <Image src={phoneSignupBanner} maxH={'70vh'} />
          </Center>
        </Flex>
        <Box
          bg={'transparent'}
          w={['100vw', '100vw', '40vw']}
          h={['90vh', '90vh', '100vh']}
        >
          <VStack
            h={['max-content', 'max-content', '100%']}
            justify={'center'}
            spacing={['20px', '20px', '100px']}
          >
            <Center w={'100%'} display={['none', 'none', 'flex']}>
              <Image src={logoWhite} maxH={['30px', '50px']} />
            </Center>
            <VStack w={'100%'} color={'white'} spacing={'10px'}>
              <Heading fontWeight={'black'}>REGISTER</Heading>
              <Text textAlign={'center'}>
                {isPhoneNumber
                  ? 'Enter your handphone number to register'
                  : 'Type the verification code we’ve sent you!'}
              </Text>
            </VStack>
            <Center display={['flex', 'flex', 'none']} h={'100%'}>
              <Image src={phoneSignupBanner} maxH={'40vh'} />
            </Center>
            <VStack w={'80%'} spacing={'20px'}>
              {isPhoneNumber ? (
                <InputGroup borderRadius={'100px'} h={'60px'} bg={'white'}>
                  <InputLeftAddon
                    children="+62"
                    h={'100%'}
                    borderLeftRadius={'100px'}
                  />
                  <Input
                    type="tel"
                    placeholder="phone number"
                    h={'100%'}
                    borderRightRadius={'100px'}
                    value={phone}
                    onChange={handlePhoneChange}
                    maxLength={11}
                    isDisabled={isClick}
                  />
                  {phone && (
                    <InputRightElement h={'100%'}>
                      <Link
                        to={''}
                        onClick={isClick ? () => {} : () => setPhone('')}
                      >
                        <RiCloseCircleFill
                          size={'20px'}
                          color={isClick ? 'gray' : 'red'}
                        />
                      </Link>
                    </InputRightElement>
                  )}
                </InputGroup>
              ) : (
                <HStack>
                  <PinInput
                    onChange={handlePinChange}
                    value={pin}
                    isDisabled={isClick}
                  >
                    <PinInputField bg={'white'} />
                    <PinInputField bg={'white'} />
                    <PinInputField bg={'white'} />
                    <PinInputField bg={'white'} />
                    <PinInputField bg={'white'} />
                    <PinInputField bg={'white'} />
                  </PinInput>
                </HStack>
              )}
              <Center>
                <Link
                  to={''}
                  onClick={
                    isClick
                      ? () => {}
                      : isPhoneNumber
                      ? handleSignin
                      : handleVerify
                  }
                >
                  <Box
                    py={'10px'}
                    bg={'#29D2E2'}
                    borderRadius={'50px'}
                    w={'150px'}
                    align={'center'}
                  >
                    {isClick ? (
                      <Spinner size={'sm'} />
                    ) : (
                      <Text color={'white'} fontWeight={'black'}>
                        {isPhoneNumber ? 'CONTINUE' : 'SUBMIT'}
                      </Text>
                    )}
                  </Box>
                </Link>
              </Center>
            </VStack>
          </VStack>
        </Box>
      </Flex>
      <Center id="recaptcha-container" display={'none'} pos={'absolute'} />
    </Box>
  );
};

export default Signup;
