import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import loginBanner from '../images/login-banner.webp';
import { GoogleLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import phoneLogin from '../images/phone-login.png';

const PopUpMenu = ({ isOpen, cancelRef, onClose, handleGoogleLogin }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent alignSelf={'center'}>
          <AlertDialogBody p={10}>
            <VStack>
              <Image src={loginBanner} />
              <Box>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={handleGoogleLogin}
                />
              </Box>
              <Box pt={'10px'}>
                <Text fontWeight={'black'}>OR</Text>
              </Box>
              <Box border={'1px'} borderColor={'#e6e6e6'} p={'10px'} borderRadius={'5px'}>
                <Link to={'/signup'}>
                  <HStack>
                    <Image src={phoneLogin} maxH={['20px', '25px', '30px']} />
                    <Text fontSize={['15px', '17px', '20px']}>
                      Login with Phone
                    </Text>
                  </HStack>
                </Link>
              </Box>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default PopUpMenu;
