import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDhFwlPi9R7jCb1GSSKvUos-TcJfW1LZBM',
  authDomain: 'beellifire.firebaseapp.com',
  databaseURL: 'https://beellifire-default-rtdb.firebaseio.com',
  projectId: 'beellifire',
  storageBucket: 'beellifire.appspot.com',
  messagingSenderId: '238251401962',
  appId: '1:238251401962:web:53c3f0755e30b8184aed93',
  measurementId: 'G-P4T7YB8X52',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
