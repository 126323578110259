import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Tag,
  Text,
} from "@chakra-ui/react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { memo, useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuthContext } from "../context/AuthContext";
import beelliGold from "../images/beelli_gold.png";

export async function loader({ params }) {
  const role_str = params.role;
  const roomID = params.roomid;
  return { role_str, roomID };
}

const DisplayBg = memo(({ roomid }) => {
  const { getStreamingBG } = useAuthContext();
  const [BG, setBG] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      getStreamingBG(roomid).then((res) => {
        if (res) {
          setBG(res);
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [getStreamingBG, roomid]);

  return (
    <Container position={"relative"} top={"10"}>
      <Center>
        <Tag size="lg" colorScheme="red" borderRadius="full">
          <Flex>
            <Box>
              <Image src={beelliGold} boxSize={"20px"} />
            </Box>
            <Text fontSize={"20px"}>{BG}</Text>
          </Flex>
        </Tag>
      </Center>
    </Container>
  );
});

const Stream = () => {
  const { role_str, roomID } = useLoaderData();
  const {
    user,
    getZegoToken,
    getUserByObjectId,
    setStreaming,
    updateStreaming,
    checkRoom,
    sendJoinMessage,
  } = useAuthContext();

  const navigate = useNavigate();
  const [isEnd, setIsEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const booted = useRef(false);

  if (isEnd) {
    setTimeout(() => {
      navigate("/home", { replace: true });
    }, 1000);
  }

  if (isLoading) {
    return <Loading />;
  }

  const appID = process.env.REACT_APP_ZEGO_APPID;
  const userID = user.objectId;
  const userName = user.name;

  const role =
    role_str === "host"
      ? ZegoUIKitPrebuilt.Host
      : role_str === "cohost"
      ? ZegoUIKitPrebuilt.Cohost
      : ZegoUIKitPrebuilt.Audience;

  const stream = async (element) => {
    // generate token
    getZegoToken(user.objectId).then((res) => {
      const token = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        appID,
        res.token,
        roomID,
        userID,
        userName
      );
      // create instance object from token
      const zp = ZegoUIKitPrebuilt.create(token);

      // start the call
      const config = {
        role: role,
        liveStreamingMode: ZegoUIKitPrebuilt.LiveStreamingMode.RealTimeLive,
      };

      const scenario = {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: config,
      };

      const branding = {
        logoURL: "https://beelli.com/public/icons/logo.png",
      };

      const lowerLeftNotification = {
        showUserJoinAndLeave: false,
        showTextChat: false,
      };

      const whiteboardConfig = {
        showAddImageButton: true,
        showCreateAndCloseButton: true,
      };

      const onJoinRoom = (u) => {
        if (role === ZegoUIKitPrebuilt.Audience) {
          if (!booted.current) {
            booted.current = true;
            checkRoom(roomID).then((res) => {
              if (!res) {
                const data = { streaming: false };
                updateStreaming(data, roomID);
                setIsEnd(true);
              }
            });
          }
        }
      };

      const onLeaveRoom = (u) => {
        const data = { streaming: false };

        if (role === ZegoUIKitPrebuilt.Host) {
          updateStreaming(data, roomID);
        }
        setIsEnd(true);
        setIsLoading(true);
      };

      const onUserJoin = (u) => {};

      const onUserLeave = (u) => {
        if (roomID.substring(0, 10) === u[0].userID) {
          setIsEnd(true);
          setIsLoading(true);
        }
      };

      const onUserAvatarSetter = (userList) => {
        userList.forEach(async (users) => {
          getUserByObjectId(users.userID).then((res) =>
            users.setUserAvatar(res.avatar.url)
          );
        });
      };

      const onLiveStart = (u) => {
        if (role === ZegoUIKitPrebuilt.Host) {
          setStreaming("live now", roomID);
        } else {
          const data = {
            viewersCountLive: { __op: "Increment", amount: 1 },
          };
          updateStreaming(data, roomID);
          sendJoinMessage(roomID);
        }
      };

      const onLiveEnd = (u) => {
        const data = { streaming: false };
        if (role === ZegoUIKitPrebuilt.Host) {
          updateStreaming(data, roomID);
        }
      };

      const onYouRemovedFromRoom = () => {
        console.log("dhanuuu onYouRemovedFromRoom");
      };

      const onInRoomMessageReceived = (m) => {
        console.log("dhanuuu onInRoomMessageReceived", m);
      };

      const onInRoomCommandReceived = (f, c) => {
        console.log("dhanuuu onInRoomMessageReceived", f, c);
      };

      const onInRoomTextMessageReceived = (m) => {
        console.log("dhanuuu onInRoomTextMessageReceived", m);
      };

      const roomConfig = {
        // 1 UI controls
        // 1.1 Global
        container: element,
        scenario: scenario,
        // console: ZegoUIKitPrebuilt.ConsoleNone,
        // 1.2 Prejoin view
        showPreJoinView: false,
        videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P,
        // 1.3 Room view
        showRoomDetailsButton: false,
        showRoomTimer: true,
        showMyCameraToggleButton:
          role === ZegoUIKitPrebuilt.Host ? true : false,
        showMyMicrophoneToggleButton:
          role === ZegoUIKitPrebuilt.Host ? true : false,
        showAudioVideoSettingsButton:
          role === ZegoUIKitPrebuilt.Host ? true : false,
        showTurnOffRemoteCameraButton: true,
        showTurnOffRemoteMicrophoneButton: true,
        showTextChat: true,
        showUserList: true,
        showRemoveUserButton: true,
        lowerLeftNotification: lowerLeftNotification,
        branding: branding,
        showLayoutButton: false,
        showScreenSharingButton: role === ZegoUIKitPrebuilt.Host ? true : false,
        showPinButton: true,
        whiteboardConfig: whiteboardConfig,
        // 1.4 Leaving view
        showLeavingView: true,
        // 2 Related event callbacks
        onJoinRoom: onJoinRoom,
        onLeaveRoom: onLeaveRoom,
        onUserJoin: onUserJoin,
        onUserLeave: onUserLeave,
        onUserAvatarSetter: onUserAvatarSetter,
        onLiveStart: onLiveStart,
        onLiveEnd: onLiveEnd,
        onYouRemovedFromRoom: onYouRemovedFromRoom,
        onInRoomMessageReceived: onInRoomMessageReceived,
        onInRoomCommandReceived: onInRoomCommandReceived,
        onInRoomTextMessageReceived: onInRoomTextMessageReceived,
      };

      if (!zp.hasJoinedRoom) {
        zp.joinRoom(roomConfig);
      } else {
        zp.destroy();
      }
    });
  };

  return (
    roomID && (
      <>
        <Box ref={stream} w={"100vw"} h={"100vh"} position={"absolute"} />
        <DisplayBg roomid={roomID} />
      </>
    )
  );
};

export default Stream;
