import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { RiCameraFill } from 'react-icons/ri';
import { BsPersonFill } from 'react-icons/bs';
import { FaCalendarAlt } from 'react-icons/fa';
import { TbGenderBigender } from 'react-icons/tb';
import Header from '../components/Header';
import awan1 from '../images/awan1.webp';
import awan2 from '../images/awan2.webp';
import ngintip from '../images/ngintip.webp';
import profile from '../images/profile.png';
import { Link } from 'react-router-dom';
import Webcam from 'react-webcam';
import { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '../components/Loading';
import { useAuthContext } from '../context/AuthContext';

const CompleteSignup = () => {
  const { completeSignup, user, googleAccount, uploadImage } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('male');
  const [birthday, setBirthDay] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imgSrc, setImgSrc] = useState(null);
  const webcamRef = useRef(null);
  const handleFirstName = event => setFirstName(event.target.value);
  const handleLastName = event => setLastName(event.target.value);
  const handleBirthday = event => setBirthDay(event.target.value);
  const toast = useToast();

  useEffect(() => {
    if (
      imgSrc !== null &&
      firstName !== '' &&
      lastName !== '' &&
      birthday !== ''
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [birthday, firstName, imgSrc, lastName]);

  const capture = useCallback(async () => {
    setIsLoading(true);
    onClose();

    try {
      const image = webcamRef.current.getScreenshot();
      await uploadImage(image);
      setImgSrc(image);
    } catch (err) {
      console.log(err);
    } finally {
      if (
        imgSrc !== null &&
        firstName !== '' &&
        lastName !== '' &&
        birthday !== ''
      ) {
        setIsValid(true);
      }
      setIsLoading(false);
    }
  }, [birthday, firstName, imgSrc, lastName, onClose, uploadImage]);

  const openCam = useCallback(() => {
    setImgSrc(null);
    onOpen();
  }, [onOpen]);

  const handleComplete = useCallback(async () => {
    if (!isValid) {
      const id = 'invalid';
      if (!toast.isActive(id)) {
        toast({
          id: id,
          position: 'top',
          status: 'error',
          isClosable: true,
          title: 'all field is required',
        });
      }

      return;
    }

    if (
      firstName.trim().split(' ').length > 1 ||
      lastName.trim().split(' ').length > 1
    ) {
      const id = 'name';
      if (!toast.isActive(id)) {
        toast({
          id: id,
          position: 'top',
          status: 'error',
          isClosable: true,
          title: 'firstname or lastname must be one word',
        });
      }

      return;
    }

    const today = new Date();
    const dob = new Date(birthday);

    if (dob > today) {
      const id = 'exceed';
      if (!toast.isActive(id)) {
        toast({
          id: id,
          position: 'top',
          status: 'error',
          isClosable: true,
          title: 'wrong date of birth',
        });
      }

      return;
    }

    const diff = today - dob;
    const age = new Date(diff);
    const getAge = Math.abs(age.getUTCFullYear() - 1970);

    if (getAge <= 16) {
      const id = 'minage';
      if (!toast.isActive(id)) {
        toast({
          id: id,
          position: 'top',
          status: 'error',
          isClosable: true,
          title: 'Minimal Age is 16 years old',
        });
      }

      return;
    }

    const prefGender = gender === 'male' ? 'female' : 'male';
    const bio = 'Hi, saya menggunakan Beelli';
    const randomUserName = Math.random().toString().substring(2, 6);
    const firstname = firstName.trim();
    const lastname = lastName.trim();
    const name = firstname + ' ' + lastname;
    const username = firstName + randomUserName;
    const uid = Math.random().toString().substring(2, 12);
    const passion = ['CCN', 'STY', 'TNY', 'GLR', 'SMG'];

    try {
      if (user.phone_number) {
        const updatePhoneData = {
          name: name,
          first_name: firstname,
          last_name: lastname,
          gender: gender,
          prefGender: prefGender,
          birthday: {
            __type: 'Date',
            iso: dob,
          },
          age: getAge,
          profile_passions: passion,
          uid: Number(uid),
        };
        await completeSignup(updatePhoneData);
      } else {
        const updateEmailData = {
          name: name,
          first_name: firstname,
          last_name: lastname,
          profile_show_gender: true,
          gender: gender,
          prefGender: prefGender,
          aboutMe: bio,
          bio: bio,
          birthday: {
            __type: 'Date',
            iso: dob,
          },
          age: getAge,
          profile_passions: passion,
          distanceFilter: 100,
          profile_show_sex_orientation: false,
          profile_sex_orientations: ['ST'],
          uid: Number(uid),
          email_public: googleAccount.email,
          email: googleAccount.email,
          ggId: googleAccount.googleId,
          popularity: 0,
          prefMinAge: 16,
          prefMaxAge: 80,
          username: username,
          has_password: false,
          role: 'user',
          prefLocationType: true,
          credit: 5,
        };
        await completeSignup(updateEmailData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    birthday,
    completeSignup,
    firstName,
    gender,
    googleAccount.email,
    googleAccount.googleId,
    isValid,
    lastName,
    toast,
    user.phone_number,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box bg={'#662D91'}>
      <Flex direction={['column', 'column', 'row']}>
        <Flex
          bg={'white'}
          w={['100vw', '100vw', '80vw']}
          h={['90vh', '90vh', '100vh']}
          borderRightRadius={[null, null, 'xl']}
          borderBottomRadius={['xl', 'xl', null]}
          p={'5'}
          direction={'column'}
        >
          <Header showMenu={'none'} />
          <Flex h={'50%'}>
            <Flex
              w="50%"
              h="100%"
              align={'start'}
              justify={'center'}
              display={['none', 'none', 'flex']}
            >
              <Image src={awan1} />
            </Flex>
            <Center w={'100%'}>
              <Link to={''} onClick={openCam}>
                <Center pos={'relative'}>
                  <Box pos={'relative'} borderRadius={'full'}>
                    {imgSrc ? (
                      <Image
                        src={imgSrc}
                        fit={'cover'}
                        boxSize={'30vh'}
                        borderRadius={'full'}
                      />
                    ) : (
                      <Box
                        p={'10'}
                        boxSize={'30vh'}
                        borderRadius={'full'}
                        bg={'#e6e6e6'}
                      >
                        <Image src={profile} fit={'cover'} />
                      </Box>
                    )}
                  </Box>
                  <Box
                    pos={'absolute'}
                    borderRadius={'full'}
                    bg={'#662D91'}
                    p={'15px'}
                    bottom={'20px'}
                    right={'20px'}
                  >
                    <RiCameraFill color={'white'} />
                  </Box>
                </Center>
              </Link>
            </Center>
            <Flex
              w="50%"
              h="100%"
              align={'end'}
              justify={'center'}
              display={['none', 'none', 'flex']}
            >
              <Image src={awan2} />
            </Flex>
          </Flex>
          <Box h={'50%'} align={'center'}>
            <VStack w={'max-content'} align={'start'} spacing={'2vh'}>
              <HStack>
                <BsPersonFill size={'30px'} color={'#666666'} />
                <Flex>
                  <Input
                    w={['40vw', '40vw', '20vw']}
                    h={'50px'}
                    placeholder={'first name'}
                    value={firstName}
                    onChange={handleFirstName}
                  />
                  <Spacer w={'2vw'} />
                  <Input
                    w={['40vw', '40vw', '20vw']}
                    h={'50px'}
                    placeholder={'last name'}
                    value={lastName}
                    onChange={handleLastName}
                  />
                </Flex>
              </HStack>
              <HStack>
                <TbGenderBigender size={'30px'} color={'#666666'} />
                <RadioGroup onChange={setGender} value={gender}>
                  <HStack>
                    <Radio value={'male'}>male</Radio>
                    <Radio value={'female'}>female</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              <HStack>
                <FaCalendarAlt size={'30px'} color={'#666666'} />
                <Input
                  w={['82vw', '82vw', '42vw']}
                  type={'date'}
                  h={'50px'}
                  placeholder={'date of birth'}
                  value={birthday}
                  onChange={handleBirthday}
                />
              </HStack>
            </VStack>
          </Box>
        </Flex>
        <Flex
          direction={'column'}
          bg={'transparent'}
          w={['100vw', '100vw', '20vw']}
          h={['10vh', '10vh', '100vh']}
        >
          <Box
            pos={'relative'}
            left={'-12px'}
            top={'100px'}
            display={['none', 'none', 'block']}
          >
            <Image src={ngintip} />
          </Box>
          <Flex
            h={'100%'}
            justify={'center'}
            align={['center', 'center', 'end']}
          >
            <Link to={''} onClick={handleComplete}>
              <Flex
                bg={isValid ? '#29D2E2' : '#e6e6e6'}
                py={'2'}
                px={'5'}
                borderRadius={'100px'}
                align={'center'}
                color={isValid ? 'white' : 'gray'}
              >
                <Text fontWeight={'black'}>COMPLETE</Text>
              </Flex>
              <Box h={'5vh'} display={['none', 'none', 'block']}></Box>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <Box h={'10px'} />
          <ModalBody>
            <Webcam
              width={'100%'}
              height={'100%'}
              mirrored={true}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            ></Webcam>
          </ModalBody>
          <ModalFooter>
            <Center w={'100%'}>
              <Link to={''} onClick={capture}>
                <Box
                  py={'10px'}
                  bg={'#29D2E2'}
                  borderRadius={'50px'}
                  w={'150px'}
                  align={'center'}
                >
                  <Text color={'white'} fontWeight={'black'}>
                    TAKE PHOTO
                  </Text>
                </Box>
              </Link>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CompleteSignup;
