import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "../utils/useLocalStorage";
import Parse from "parse/dist/parse.min.js";

const AuthContext = createContext();

const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [googleAccount, setGoogleAccount] = useState("");
  const [zg, setZg] = useState(null);
  const { setItem, getItem, clearSession } = useLocalStorage();
  const headers = {
    "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APPLICATION_ID,
    "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
    "X-Parse-Revocable-Session": 1,
    "Content-Type": "application/json",
  };

  const restUser = "/parse/users";
  const restLogin = "/parse/login";
  // const restStreaming = "/parse/classes/Streaming";
  const restStreaming = "/parse/classes/StreamingZego";
  const restCloud = "/parse/functions";

  useEffect(() => {
    const getUser = getItem("user");
    const getGoogle = getItem("google");

    if (getUser) {
      setUser(getUser);
    }

    if (getGoogle) {
      setGoogleAccount(getGoogle);
    }
  }, [getItem]);

  const login = async (token) => {
    const decode = jwtDecode(token);
    const googleId = decode.sub;
    const email = decode.email;
    const googleIdentity = { email: email, googleId: googleId };
    setGoogleAccount(googleIdentity);
    setItem("google", googleIdentity);

    const authData = {
      authData: {
        google: {
          id: googleId,
          id_token: token,
        },
      },
    };

    try {
      const result = await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL + restUser,
        authData,
        { headers: headers }
      );

      const sessionData = {
        objectId: result.data.objectId,
        sessionToken: result.data.sessionToken,
      };

      const checkExistsData = {
        email: email,
      };

      await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL +
          restCloud +
          "/destroy_user_sessions_web",
        sessionData,
        {
          headers: headers,
        }
      );

      const checkExists = await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL + restCloud + "/checkExistsUser",
        checkExistsData,
        {
          headers: headers,
        }
      );

      if (checkExists.data.result === "empty") {
        await axios.post(
          process.env.REACT_APP_PARSE_HOST_URL +
            restCloud +
            "/destroyUserExists",
          checkExistsData,
          {
            headers: headers,
          }
        );
      }
      setUser(result.data);
      setItem("user", result.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const loginWithPhone = async (phone) => {
    const params = { where: { phone_number: phone } };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restUser,
        { params: params, headers: headers }
      );

      if (result.data.results.length === 0) {
        _processSignup(phone);
      } else {
        const username = result.data.results[0].username;
        const password = result.data.results[0].secondary_password;
        _processLogin(username, password);
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const _processLogin = async (username, password) => {
    const authData = {
      username: username,
      password: password,
    };
    try {
      const result = await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL + restLogin,
        authData,
        { headers: headers }
      );

      const sessionData = {
        objectId: result.data.objectId,
        sessionToken: result.data.sessionToken,
      };

      await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL +
          restCloud +
          "/destroy_user_sessions_web",
        sessionData,
        {
          headers: headers,
        }
      );

      setUser(result.data);
      setItem("user", result.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const _processSignup = async (phone) => {
    const password = Math.random().toString().substring(2, 12);
    const bio = "Hi, saya menggunakan Beelli";
    const authData = {
      username: phone,
      password: password,
    };
    try {
      const result = await axios.post(
        process.env.REACT_APP_PARSE_HOST_URL + restUser,
        authData,
        { headers: headers }
      );

      const objectId = result.data.objectId;
      const sessionToken = result.data.sessionToken;
      const phoneNumberFull = process.env.REACT_APP_COUNTRY_CODE + phone;
      const headersUpdate = {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APPLICATION_ID,
        "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
        "X-Parse-Revocable-Session": 1,
        "X-Parse-Session-Token": sessionToken,
        "Content-Type": "application/json",
      };
      const updateData = {
        profile_show_gender: true,
        aboutMe: bio,
        bio: bio,
        distanceFilter: 100,
        profile_show_sex_orientation: false,
        profile_sex_orientations: ["ST"],
        phone_number_full: phoneNumberFull,
        phone_number: phone,
        popularity: 0,
        prefMinAge: 16,
        prefMaxAge: 80,
        has_password: true,
        secondary_password: password,
        role: "user",
        prefLocationType: true,
        credit: 5,
      };

      await axios.put(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/" + objectId,
        updateData,
        { headers: headersUpdate }
      );

      const currentUser = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/me",
        { headers: headersUpdate }
      );

      setUser(currentUser.data);
      setItem("user", currentUser.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const completeSignup = async (data) => {
    const headersUpdate = {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APPLICATION_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
      "X-Parse-Revocable-Session": 1,
      "X-Parse-Session-Token": user.sessionToken,
      "Content-Type": "application/json",
    };

    try {
      setUser(null);
      setGoogleAccount(null);
      clearSession();
      await axios.put(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/" + user.objectId,
        data,
        { headers: headersUpdate }
      );

      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/me",
        { headers: headersUpdate }
      );

      setUser(result.data);
      setItem("user", result.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateLocation = async (lat, lng) => {
    const url = `${process.env.REACT_APP_GOOGLE_GEOCODE_URL}?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_GEOCODE_APIKEY}`;
    try {
      const result = await axios.get(url);

      const location = `${result.data.results[0].address_components[1].long_name}, ${result.data.results[0].address_components[2].long_name}, ${result.data.results[0].address_components[5].long_name}`;
      const headersUpdate = {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APPLICATION_ID,
        "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_REST_API_KEY,
        "X-Parse-Revocable-Session": 1,
        "X-Parse-Session-Token": user.sessionToken,
        "Content-Type": "application/json",
      };

      const data = {
        hasGeopoint: true,
        location: location,
        geopoint: {
          __type: "GeoPoint",
          latitude: lat,
          longitude: -lng,
        },
      };

      await axios.put(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/" + user.objectId,
        data,
        { headers: headersUpdate }
      );

      const currentUser = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restUser + "/me",
        { headers: headersUpdate }
      );

      setUser(currentUser.data);
      setItem("user", currentUser.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const uploadImage = async (data) => {
    const filename =
      Math.random().toString(20).substring(2, 14) + "_avatar.jpeg";

    Parse.initialize(
      process.env.REACT_APP_PARSE_APPLICATION_ID,
      process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
    );
    Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

    const User = new Parse.User();
    const query = new Parse.Query(User);

    Parse.User.current();
    try {
      await Parse.User.become(user.sessionToken);

      let updateImage = await query.get(user.objectId);
      updateImage.set("avatar", new Parse.File(filename, { base64: data }));
      updateImage.set("avatar1", new Parse.File(filename, { base64: data }));
      try {
        await updateImage.save();
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getUserStreaming = async () => {
    const params = {
      where: `{"streaming":true,"AuthorId":{"$ne":"${user.objectId}"}}`,
      include: "Author",
    };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
        { params: params, headers: headers }
      );

      return result.data.results;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getZegoToken = async (userId) => {
    try {
      const uri = process.env.REACT_APP_ZEGOTOKEN_URL;
      const params = {
        userId: userId,
      };
      const result = await axios.get(uri, { params: params });

      return result.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const logout = () => {
    setUser(null);
    setGoogleAccount(null);
    clearSession();
  };

  const setStreaming = async (title, channel) => {
    const data = {
      streaming: true,
    };

    const params = {
      where: `{ "streaming_channel":"${channel}"}`,
    };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
        { params: params, headers: headers }
      );

      if (result.data.results.length !== 0) {
        await axios.put(
          process.env.REACT_APP_PARSE_HOST_URL +
            restStreaming +
            "/" +
            result.data.results[0].objectId,
          data,
          { headers: headers }
        );
      } else {
        const createData = {
          Author: {
            __type: "Pointer",
            className: "_User",
            objectId: user.objectId,
          },
          streaming_channel: channel,
          AuthorId: user.objectId,
          AuthorUid: user.uid,
          image: {
            __type: "File",
            name: user.avatar.name,
          },
          private: false,
          streaming: true,
          viewersCountLive: 0,
          streaming_diamonds: 0,
          live_title: title,
        };

        await axios.post(
          process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
          createData,
          {
            headers: headers,
          }
        );
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateStreaming = async (data, channel) => {
    const params = {
      where: `{ "streaming_channel":"${channel}","streaming":true}`,
    };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
        { params: params, headers: headers }
      );

      if (result.data.results.length !== 0) {
        await axios.put(
          process.env.REACT_APP_PARSE_HOST_URL +
            restStreaming +
            "/" +
            result.data.results[0].objectId,
          data,
          { headers: headers }
        );
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getStreamingBG = async (channel) => {
    const params = {
      where: `{ "streaming_channel":"${channel}","streaming":true}`,
    };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
        { params: params, headers: headers }
      );

      if (result.data.results.length !== 0) {
        return result.data.results[0].streaming_diamonds;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateSelfStreaming = async (data, authorId) => {
    const params = {
      where: `{ "AuthorId":"${authorId}","streaming":true}`,
    };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restStreaming,
        { params: params, headers: headers }
      );

      if (result.data.results.length !== 0) {
        await axios.put(
          process.env.REACT_APP_PARSE_HOST_URL +
            restStreaming +
            "/" +
            result.data.results[0].objectId,
          data,
          { headers: headers }
        );
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getUserByObjectId = async (objectId) => {
    const params = { where: { objectId: objectId } };

    try {
      const result = await axios.get(
        process.env.REACT_APP_PARSE_HOST_URL + restUser,
        { params: params, headers: headers }
      );

      return result.data.results[0];
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const checkRoom = async (roomId) => {
    try {
      const result = await axios.get(
        process.env.REACT_APP_ZEGOTOKEN_URL + "checkroom/" + roomId
      );

      return result.data.status;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const sendJoinMessage = async (roomId) => {
    try {
      await axios.get(
        process.env.REACT_APP_ZEGOTOKEN_URL +
          "broadcast/" +
          roomId +
          "/" +
          user.objectId +
          "/" +
          user.name +
          "/started watching;WITHAVATAR"
      );
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        googleAccount,
        zg,
        login,
        logout,
        loginWithPhone,
        completeSignup,
        uploadImage,
        updateLocation,
        getUserStreaming,
        getUserByObjectId,
        getZegoToken,
        setZg,
        setStreaming,
        updateStreaming,
        checkRoom,
        updateSelfStreaming,
        sendJoinMessage,
        getStreamingBG,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuthContext, AuthProvider };
