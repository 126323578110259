import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BsBroadcast} from "react-icons/bs";
import { FaHome, FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuthContext } from "../context/AuthContext";
import logo from "../images/logo.png";

const NavigationBar = ({ roomid, logout, user }) => {
  return (
    <HStack
      bg={"#662D91"}
      borderRadius={"full"}
      w={"100%"}
      px={"10px"}
      py={["10px"]}
      color={"white"}
    >
      <Flex w={"100%"}>
        <Center w={"100%"}>
          <Link to={"/"} replace={true}>
            <Box color={"white"}>
              <Center>
                <FaHome />
              </Center>
              <Text display={["none", "block", "block"]}>Home</Text>
            </Box>
          </Link>
        </Center>
        <Spacer />
        <Center w={"100%"}>
          <Link to={`live/role/host/roomid/${roomid}`} replace={true}>
            <Box color={"white"}>
              <Center>
                <BsBroadcast />
              </Center>
              <Text display={["none", "block", "block"]}>Live</Text>
            </Box>
          </Link>
        </Center>
        <Spacer />
        {/* <Center>
      <Link to={""} replace={true}>
        <HStack color={"white"}>
          <BsPersonFill />
          <Text>Profile</Text>
        </HStack>
      </Link>
    </Center>
    <Spacer /> */}
        <Center w={"100%"}>
          <Link to={""} replace={true} onClick={logout}>
            <Box color={"white"}>
              <Center>
                <FaPowerOff />
              </Center>
              <Text display={["none", "block", "block"]}>Logout</Text>
            </Box>
          </Link>
        </Center>
      </Flex>
      <HStack color={"white"} w={"100%"} justify={"end"}>
        <Text textAlign={"end"} display={["none", "block", "block"]}>
          Welcome Back,{" "}
          <Text as={"span"} fontWeight={"black"}>
            {user.first_name}
          </Text>
        </Text>
        <Text
          fontWeight={"black"}
          textAlign={"end"}
          display={["block", "none", "none"]}
        >
          {user.first_name}
        </Text>
        <Avatar src={user.avatar.url} />
      </HStack>
    </HStack>
  );
};

const Dummy = () => {
  return (
    <>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
      <Link to="">
        <Box>
          <Avatar size={["xl", "xl", "2xl"]} objectFit={"cover"} />
        </Box>
      </Link>
    </>
  );
};

const Home = () => {
  const { user, getUserStreaming, updateSelfStreaming, logout } =
    useAuthContext();
  const [streamingUser, setStreamingUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const roomID = user.objectId + Math.random().toString().substring(2, 6);

  const _init = useCallback(async () => {
    try {
      const update = { streaming: false };
      const data = await getUserStreaming();
      await updateSelfStreaming(update, user.objectId);
      setStreamingUser(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [getUserStreaming, updateSelfStreaming, user.objectId]);

  useEffect(() => {
    _init();
  }, [_init]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <VStack
        w={"100vw"}
        h={["70vh", "70vh", "100vh"]}
        bg={"white"}
        py={["10px", "10px", "30px"]}
        px={["10px", "10px", "30px"]}
        spacing={"30px"}
        position={"absolute"}
      >
        <HStack
          w={"100%"}
          display={["none", "none", "flex"]}
          columnGap={"15px"}
        >
          <Center w={"20%"}>
            <Image src={logo} />
          </Center>
          <Center w={"100%"}>
            <NavigationBar roomid={roomID} logout={logout} user={user} />
          </Center>
        </HStack>
        <Center w={"70%"} display={["block", "block", "none"]}>
          <Image src={logo} />
        </Center>
        <Box h={"100%"}>
          {streamingUser.length !== 0 ? (
            <SimpleGrid
              columns={[3, 4, 5]}
              spacing={["10px", "20px", "50px"]}
              mb={"20px"}
            >
              {streamingUser.map((res) => {
                return (
                  <Link
                    to={`live/role/audience/roomid/${res.streaming_channel}`}
                    key={res.objectId}
                  >
                    <Box>
                      <Avatar
                        size={["xl", "xl", "2xl"]}
                        src={res.image.url}
                        objectFit={"cover"}
                      />
                    </Box>
                  </Link>
                );
              })}
            </SimpleGrid>
          ) : (
            <Center h={"100%"}>
              <Heading>No user live</Heading>
            </Center>
          )}
        </Box>
      </VStack>
      <Center
        p={"10px"}
        position={"fixed"}
        w={"100%"}
        top={"90%"}
        bottom={"100%"}
        display={["flex", "flex", "none"]}
      >
        <NavigationBar roomid={roomID} logout={logout} user={user} />
      </Center>
    </>
  );
};

export default Home;
