import { createBrowserRouter } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth';
import Home from '../pages/Home';
import CompleteSignup from '../pages/CompleteSignup';
import Welcome from '../pages/Welcome';
import Error from '../pages/Error';
import Signup from '../pages/Signup';
import Reward from '../pages/Reward';
import Stream, { loader as streamLoader } from '../pages/Stream';
import HaveUser from '../components/HaveUser';

const routers = createBrowserRouter([
  {
    path: '/',
    element: <Welcome />,
    errorElement: <Error />,
  },
  {
    path: 'reward',
    element: <Reward />,
    errorElement: <Error />,
  },
  {
    path: 'home',
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: 'home/live/role/:role/roomid/:roomid',
    element: (
      <RequireAuth>
        <Stream />
      </RequireAuth>
    ),
    loader: streamLoader,
  },
  {
    path: 'complete',
    element: (
      <RequireAuth>
        <CompleteSignup />
      </RequireAuth>
    ),
  },
  {
    path: 'signup',
    element: (
      <HaveUser>
        <Signup />
      </HaveUser>
    ),
  },
]);

export { routers };
